/**
 * @author CHEN GUANGPENG
 * @date 2019/12/13
 */
import React, { useState, useEffect } from 'react';
import styles from '../assets/css/brand.module.css';
import HistoryStep from '../components/historyStep/index';
import { loadConfig } from '../service/api';
import Layout from '../components/layout/index'

const getHistory = async () => {
  let history = [];
  try {
    const conf = await loadConfig();
    const { brandPage } = conf.data || {};
    history = brandPage.history || [];
  } catch (e) {
    console.error(e);
  }
  return history;
};

const Introduction = () => {
  const { introduction, coverImg, text, main, title } = styles;
  return (
    <div className={introduction}>
      <div className={main}>
        <div className={coverImg}>
          <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/brand_introduction.png"></img>
          <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210312143421.jpg"></img>
        </div>
        <div className={text}>
          <h2 className={title}>简介</h2>
          <p>LOCALS路客精品民宿创立于2015年，在全球超过65个城市拥有20000+套精品民宿，房源覆盖广州、北京、上海、成都、重庆、杭州、武汉、厦门、西安等全国多个城市，其中一二线城市占80%。直接管理运营达到80000间特色精品民宿，接待人次超过2000万人次。2021年，路客还将开辟更多三、四线城市的房源渠道，为用户提供更加多样化的选择。</p> 
          <br />
          <p>路客精品民宿始终坚持为每一位城市旅行者打造旅途中的家，传递“与当地人交朋友，像当地人一样生活”的独特旅行方式，重新定义旅宿的意义，致力于成为新一代个性化住宿集团。</p>
          <p>路客希望分享全球精英房东的故事、品位、爱好和生活态度，让每一位客人住进充满艺术感和设计感的家，体验当地最具特色的吃喝玩乐。</p>
          <br />
          <p>路客品牌拥有两大产品模块，即路客精品民宿（自营）和路客轻居。其中，路客精品民宿为路客体系下的产物，严格遵循路客自营标准，为住客提供高品质民宿服务。而路客轻居是在OPEN策略下“为社会房东赋能”的践行实践，汇集了除路客外的其它品牌房源，与路客自营房源共享平台。住宿风格、房源位置日益多元化，价格区间上也增加了更多的选择，进一步满足更多住客的入住需求。</p>
          <br />
          <p>无论是路客精品民宿，还是路客轻居，都涵盖民宿、酒店、公寓、别墅、行政会所等住宿产品，为家庭旅游、亲子度假、商旅出差、会议团建等提供预订住宿服务。</p>
        </div>
      </div>
    </div>
  );
};

const Slogan = () => {
  return (
    <div className={styles.slogan}>
      <div className={styles.container}>
        <h2 className={styles.title}>品牌理念</h2>
        <p>Travel like a local，we are locals！与当地人交朋友，像当地人一样旅行！</p> 
      </div>
    </div>
  );
};

const Product = () => {
  return (
    <div className={styles.product}>
      <h2 className={styles.title}>路客产品模块</h2>
      <div className={styles.content}>
        <div>
          <p className={styles.tips}>①路客精品民宿</p>
          <p>路客精品民宿即路客自营房源，在选址、筹建、设计、品质、生活用品、卫生清洁、营销运营等方面，都需按照路客的标准进行统一化操作管理。路客自营房源涵盖范围广，包括民宿（精品民宿、商务精选、行政会所）、酒店、公寓、别墅等，为不同的住客提供高品质的房源和配套设施，以满足个性化住宿的需求。</p>
        </div>
        <div>
          <p className={styles.tips}>②路客轻居</p>
          <p>路客轻居是与路客自营民宿并驾齐驱的一大类别，汇集了除路客自营房源外的其它品牌房源，与路客自营房源共享平台，实现全网房源的一站式推广。路客轻居是在OPEN策略下“为社会房东赋能”的践行实践，也是打造多元化住宿的进一步升级。路客轻居与路客自营民宿的融合，大幅提升了用户在房源预定层面的体验，住宿风格、房源位置日益多元化，价格区间上也增加了更多的选择，轻松便可满足全方位入住需求，选择多样化，服务更贴心。</p>
        </div> 
      </div> 
    </div>
  );
};

const ProductLine = () => {
  return (
    <div className={styles.productLine}>
      <div className={styles.container}>
        <h2 className={styles.title}>路客产品线</h2>
        <p>LOCALS路客是国内发展最快，管理规模最大的精品民宿品牌，为了满足日益增长的个性化住宿需求，目前路客已经开拓了路客HOMES、路客HOTELS、路客VILLAS三大产品线，实现了多条产品线与多维业态的联动。</p> 
        <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/%E7%BD%91%E9%A1%B5%E8%B7%AF%E5%AE%A2%E4%BB%8B%E7%BB%8D-05.png"></img>
        <p className={styles.tips1}>路客HOMES（路客分散式精品民宿）</p>
        <p className={styles.text}>1）当地人房东。寻找喜欢互联网、旅行和分享的高素质房东，分享他们的家。 </p>
        <p className={styles.text}>2）优越的地理位置。房源主要选择在城央CBD、地标建筑、历史古宅等优越地理位置。</p>
        <p className={styles.text}>3）坚持“一房一设计”。每套房源由独立专业设计师帮助房东重新装饰内部空间。打造个性化IP民宿、行政会所、商务精选、精品民宿。</p>
        <p className={styles.text}>4）配套运营管理服务。帮助房东解决房源线上线下运营全部工作。</p>
        <br/>
        <p className={styles.tips2}>路客HOTELS（路客集中式精品酒店、客栈、公寓）</p>
        <p className={styles.text}>1）设计风格别树一格。路客精品民宿旗下汇集城市最具设计感的精品度假酒店，探索新一代旅行的意义。</p>
        <p className={styles.text}>2）结合城市本土特色。每一家路客HOTELS均别具特色，凝聚当地文化积淀和城市独特魅力。</p>
        <p className={styles.text}>3）提供个性化定制服务。从露天聚餐、私人酒会到围炉夜唱，路客HOTELS个性化定制的贴心服务为宾客打造极具活力的社交化住宿空间。</p>
        <p className={styles.text}>4）强调品质，体现温度。路客HOTELS每一家门店均拥有绝佳的地理位置和自然环境，捕获充满人文气息的城市光影，让温馨舒适陪伴旅行者的生活，让每一次旅行度假都超越期待。</p>
        <br/>
        <p className={styles.tips3}>路客VILLAS（路客近郊度假别墅）</p>
        <p className={styles.text}>1）为新一代旅行者而设的高端别墅美学空间，聚焦近郊旅行，为城市精英打造高品质生活体验。</p>
        <p className={styles.text}>2）路客VILLAS坐落在自然景区的山林溪谷中，四季皆有风景。</p>
        <p className={styles.text}>3）每间别墅都由屡获殊荣的设计大咖倾力打造，集户外花园、私人影院、室内恒温游泳池、娱乐健身等设施于一身，打造度假生活终极体验。</p>
        <p className={styles.text}>4）遵循“真心换肯定”的服务理念，在卫生、床品、洗沐护上严格把控品质，力求每一个入住的客人都能满意。</p>
        <p className={styles.text}>5）路客VILLAS进驻国内别墅度假市场，在上海、杭州、海南、南京、青岛、重庆、恩施宁波、西昌、广东清远、从化、惠州、阳江、佛山、花都、珠海等均有在营别墅。</p>
      </div>
    </div>
  );
};

const Design = () => {
  return (
    <div className={styles.design}>
      <h2 className={styles.title}>路客设计理念</h2>
      <p>历经多年的打磨，路客房源无论是在设计感还是在居住体验方面，都更能迎合市场上多种群体的审美需求。</p>
      <p>2000+资深设计师“一房一设计”，紧跟当代时尚潮流，现代极简风、欧美风、现代商务风、复古风......各种户型满足独居、情侣同居、朋友合租、家庭等出游居住需求。</p>
      <br/>
      <p>在体验上，路客和多个家居新零售供应商达成深度战略合作协议，大品牌品质保障，并深入供应链，与大牌工厂强强联手，打造高品质，提供更具物美价优的产品。与此同时，路客精品民宿在智能门锁、厨房家电、餐具卫浴等方面都为住客考虑细致，并广受住客们及房东一致好评。</p>
      <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/%E7%BD%91%E9%A1%B5%E8%B7%AF%E5%AE%A2%E4%BB%8B%E7%BB%8D-02%E5%89%AF%E6%9C%AC.jpg" alt="img"/>
    </div>
  );
};

const Hudson = () => {
  return (
    <div className={styles.hudson}>
      <div className={styles.container}>
        <h2 className={styles.title}>民宿宝</h2>
        <p>民宿宝是路客实现对社会房东OPEN的载体，可供广大民宿主下载使用。</p>
        <p>民宿宝汇集了智能接待AAI、PMS运营、智能安防等多款强大实用的系统，从而一站式轻松解决多平台运营的难题。</p>
        <p>针对房态、房价、直连、库存管理、多平台运营、账务管理、保洁等功能进行深入开发，有利于房东或运营者更加轻松地运营和管理多个房源。</p>
        <br/>
        <p>随着民宿宝的不断升级，路客将投入大量的成本深耕其内在技术，使其成为整个行业的标杆，让更多人能用上路客民宿宝，享受科技带来的个性化住宿的便利、高效；</p>
        <br/>
        <p>同时，民宿宝产品已经在酒店大规模的应用，很多客栈、公寓和酒店都已经在用它。它的移动PMS（无人入住系统）已经可以实现无前台入住，对于酒店行业来说，是一次运营和管理流程上的全面革新。</p>
      </div>
    </div>
  );
};

const Business = () => {
  return (
    <div className={styles.business}>
      <h2 className={styles.title}>运营优势</h2>
      <div className={styles.tips}>覆盖全国具有丰富经验的民宿运营团队体系：运营支持中台-路客管家-民宿保洁员-路客学院</div>
      <div className={styles.content}>
        <div>
          <p className={styles.tips}>01 运营支持中台</p>
          <p className={styles.tip}>1）强大的线上运营：</p>
          <p>丰富民宿线上运营经验，260度监控系统和流量排名、智能调价和活动报名。</p>
          <p className={styles.tip}>2）24小时服务支持：</p>
          <p>24小时在线接单咨询、24小时入住接待、24小时渠道和房客和房东服务对接。</p>
          <p className={styles.tip}>3）数据技术支持：</p>
          <p>流量、定价、服务、房源质量、卫生等数据化，全智能化自动化解决方案。</p>
        </div>
        <div>
          <p className={styles.tips}>02 路客管家</p>
          <p className={styles.tip}>1）规模实力： </p>
          <p>超过500+人管家团队，一对一房东服务和朋友式关怀接待服务。</p>
          <p className={styles.tip}>2）服务范围： </p>
          <p>负责接待客人、房东服务和房源运营管理维护。</p>
          <p className={styles.tip}>3）品质房源和良好入住保证： </p>
          <p>为客人提供细致的入住指引直到客人check-out。</p>
        </div> 
      </div> 
      <div className={styles.content}>
        <div>
          <p className={styles.tips}>03 专业民宿保洁员</p>
          <p className={styles.tip}>1）规模实力： </p>
          <p>专业服务人员管理体系，线上自主派单，保洁服务状态跟踪反馈。</p>
          <p className={styles.tip}>2）服务目标： </p>
          <p>安全、干净、舒适、标准、节能。</p>
          <p className={styles.tip}>3）保洁服务状态跟踪： </p>
          <p>保洁员工自查，总管+房东查房，暗访抽查，三重把关。</p>
        </div>
        <div>
          <p className={styles.tips}>04 路客学院</p>
          <p className={styles.tip}>1）规模实力：</p>
          <p>5000+专业民宿人才培训输送，打造民宿界最极致的管家文化。</p>
          <p className={styles.tip}>2）理念：</p>
          <p>关怀住客，心系房东。</p>
        </div> 
      </div> 
    </div>
  );
};

const Team = () => {
  return (
    <div className={styles.team}>
      <div className={styles.container}>
        <h2 className={styles.title}>核心团队</h2>
        <p className={styles.bold}>路客民宿创始人苏同民，原铂涛集团高级副总裁，简约生活事业群总裁兼7天酒店事业部CEO。从事酒店和旅游行业超过10年，是“7天连锁酒店”早期的创始团队成员之一，带领“7天”品牌从300家酒店发展到3000家酒店，创造一年新开400家酒店的纪录。并创立了“7天优品酒店”“IU互联网酒店”和“派－轻标准酒店”。 </p>
        <p className={styles.bold}>路客团队核心创始人21人，他们来自原铂涛集团、腾讯、阿里巴巴、携程、唯品会，对民宿事业的热爱让他们走到一起。</p>
        <p className={styles.bold}>如今团队已超过5000人的民宿行业精英。</p>
        <br/>
        <p>400人的Corporate运营团队，全国划分为8个大区和区域管理团队。</p>
        <p>150人经验丰富的BD团队，8年以上行业经验的占80%，多来自于中国知名酒店集团和地产商。过去4年中累计开发8万间房。</p>
        <p>以品牌为核心，搭建运营系统operation system，以品牌标准为基础建立FF&E，OS&E and SOP。</p>
        <p>根据SOP，开展培训和考试，通过标准考试后才能上岗。培训学院根据品牌标准和运营系统开展培训，同时培养酒店总经理，值班经理和客房总监等核心人才，已培训人才超过300人。</p>
        <p>QA店内巡查和陌生访客检查，监控每个酒店的执行标准和服务品质</p>
        <p>7*24小时客服中心接受客户投诉，确保客户利益，维护品牌承诺</p>
        <p>有完善的Salt会员点评系统</p>
        <p>有完善覆盖全国的供应链平台，具有竞争力的采购价格。</p>
        <br/>
        <p className={styles.bold}>路客拥有专业化、规模化和系统化的民宿管理团队，包括BD团队、工程快改团队、设计师、摄影师、供应链、线下全职管家、线上运营团队、保洁人员、技术团队，构建起从签约、设计改造、线上和线下运营管理的民宿全产业链条。</p>
        <div className={styles.imgs}>
          <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/web_locals/WechatIMG985.png?x-oss-process=image/resize,w_750/quality,Q_100" alt="" />
          <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/web_locals/WechatIMG984.png?x-oss-process=image/resize,w_750/quality,Q_100" alt="" />
        </div>
      </div>
    </div>
  );
};

const Cooperate = () => {
  return (
    <div className={styles.cooperate}>
      <h2 className={styles.title}>合作商</h2>
      <p className={styles.bold}>加盟商：</p>      
      <p>合作过的加盟商资源超过3000人，累计投资超过1000家酒店，具有非常强的投资能力。</p> 
      <br/>
      <p className={styles.bold}>战略合作地产商：</p>      
      <p>合作过并建立战略合作关系的中国地产商超过100家。其中包括知名地产商：万达，融创，绿地，龙湖，万科，碧桂园，凤凰文旅等。</p>      
    </div>
  );
};

const History = () => {
  const { history: historyStyle, historyContainer, title, main } = styles;
  const [history, setHistory] = useState([]);
  useEffect(() => {
    (async () => {
      const historyList = await getHistory();
      setHistory(historyList);
    })();
  }, []);
  return (
    <div className={historyStyle}>
      <div className={historyContainer}>
        <h1 className={title}>融资进度</h1>
        <div>
          {history.map((item, index) => (
            <HistoryStep
              key={index}
              isEnd={index === history.length - 1}
              timing={item.timing}
              introduction={item.introduction}
            ></HistoryStep>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * 独角兽展示模块
 */
const Unicorn = () => {
  const { unicorn, title, main, text, img } = styles;
  return (
    <div className={unicorn}>
      <h1 className={title}>路客有望成为民宿品牌的独角兽</h1>
      <div className={main}>
        <div className={text}>
          截至目前，路客精品民宿已经集齐中国投资界「全明星阵容」——顺为资本（雷军、许达来)、洪泰基金(俞敏洪、盛希泰)、真格基金(徐小平、王强)、翰同资本(王倩、赵强)、广东文投(柳家平)。
          <br />
          据公开资料显示，路客单笔融资金额在非平台类民宿领域已经创造了行业最高纪录,被赞誉为中国民宿界的“创业黑马”和拥有实力最强的团队。
        </div>
        <div className={img}>
          <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/brand_people.png"></img>
        </div>
      </div>
    </div>
  );
};

const Award = () => {
  return (
    <div className={styles.award}>
      <div className={styles.container}>
        <h2 className={styles.title}>路客精品民宿获奖</h2>
        <p>2017 最佳新开业民宿奖</p>
        <p>2017 生活美学民宿风尚奖</p>
        <p>2017荣获旅游卫视颁发“民宿设施最佳口碑奖”</p>
        <p>2018 荣获民宿最佳管理奖</p>
        <p>2018 荣获广东青年创新创业大赛暨首届粤港澳大湾区青年创新创业大赛“成长组三等奖”</p>
        <p>2018 最佳民宿品牌奖</p>
        <p>2018 最具特色风情民宿</p>
        <p>2018 广东住房租赁行业十大影响力品牌</p>
        <p>2019 上半年最受消费者欢迎民宿品牌 </p>
        <p>2019 摘获MBI2019年度民宿影响力品牌金航奖</p>
        <p>2019 途家民宿最佳合作民宿品牌 </p>
        <p>2019 贝壳租赁平台优秀合作伙伴</p>
        <p>2021 路客已获各类计算机软件著作权11项</p>
        <img src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com//localhomeqy/%E5%9B%BE%E5%B1%82%200.png" alt=""></img>
      </div>
    </div>
  );
};

export default () => {
  const { appContainer, banner } = styles;
  return (
    <Layout>
        <div className={banner}></div>
        <Introduction></Introduction>
        <Slogan></Slogan>
        <Product></Product>
        <ProductLine></ProductLine>
        <Design></Design>
        <Hudson></Hudson>
        <Business></Business>
        <Team></Team>
        <Cooperate></Cooperate>
        <History></History>
        <Unicorn></Unicorn>
        <Award></Award>
    </Layout>
  );
};
